import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {APIResponse} from '@core/interfaces/system/system-common';
import {HttpClient} from '@angular/common/http';
import {PasswordRequirementsWrapper} from '@core/interfaces/common/auth';

@Injectable()
export class AuthApi {
    private readonly prefix: string = 'authorize';

    constructor(private http: HttpClient) {}

    get apiUrl(): string {
        return environment.NODE_BASE_URL;
    }

    public resetPasswordWithToken(token: string, newPassword: string, confirmPassword: string): Observable<any> {
        const body = {
            token: token,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        };
        return this.http.put(`${this.apiUrl}/${this.prefix}/reset-password`, body);
    }

    public verifyEmailWithToken(token: string): Observable<any> {
        return this.http.put(`${this.apiUrl}/${this.prefix}/verify-email`, {token: token});
    }

    public passwordValidationByToken(
        token: string,
        password: string,
    ): Observable<APIResponse<PasswordRequirementsWrapper>> {
        return this.http.post(
            `${this.apiUrl}/${this.prefix}/password/validate/${token}`,
            {password: password},
            {},
        ) as Observable<APIResponse<PasswordRequirementsWrapper>>;
    }

    public requestPasswordResetEmail(username: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/${this.prefix}/request-password-reset`, {username: username});
    }
    public switchOrganization(tenantId: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/${this.prefix}/switch-tenant`, {tenantId: tenantId});
    }
}
